
import React from 'react';
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ApplicationState, createPrintHeadsShow, createPrintHeadsHide, CardSize, ActionTypes } from './redux/store'
import {PrintHead} from './PrintHead'
import {PrintTextField} from './PrintTextField'
import './Envelope.css';
import './Monitor.css';
import { OrderItem } from './OrderItem';

export interface EnvelopeStateProps {
    showPrintHeads: boolean,
    cardSize: CardSize,
    orderItem: OrderItem
}
interface EnvelopeDispatchProps {}
type EnvelopeProps = EnvelopeStateProps & EnvelopeDispatchProps;

let unprintableMargins = {
    top: 10,
    left: 15
};

let square ={
    width: 165,
    height: 165
};

let standard ={
    width: 250,
    height: 150
};


class Envelope extends React.Component<EnvelopeProps, {}> {

    render(){

        let currentSize = this.props.cardSize === "square" ? square : standard;

        let viewBox = `0 0 ${standard.width} ${standard.height}`

        
        return (    
        <div className="env">
            <svg id="svg" viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">

                <g id="envGroup" fill="white">
                    <span>address={this.props.orderItem.Address}</span>
                    <EnvelopeShape width={currentSize.width} height={currentSize.height} />

                    <PrintTextField x={unprintableMargins.left} width={100} height={40} text={this.props.orderItem.Note} />

                    <PrintTextField x={120} y={unprintableMargins.top} width={30} height={10} text={this.props.orderItem.Price} />

                    <PrintTextField x={155} y={unprintableMargins.top} width={30} height={10} text={this.props.orderItem.Id} />

                    <PrintTextField x={120} y={20} width={30} height={10} text={this.props.orderItem.MatchCode} />

                    <Stamp />

                    <PrintTextField x={75} y={70} width={120} height={10} text={this.props.orderItem.Name} />

                    <PrintTextField x={75} y={78} width={120} height={40} text={this.props.orderItem.Address} />

                    <PrintHeads showPrintHeads={this.props.showPrintHeads} />
                </g>
            </svg>
        </div>);
    }
}

const EnvelopeShape: React.SFC<{ width: number, height: number }> = (props) => {
    return (
        <rect id="envelope" x="0" y="0" width="250" height="150"></rect>
    )
}

const PrintHeads: React.SFC<{ showPrintHeads: boolean }> = (props) => {

    if(props.showPrintHeads){
        return (
            <>
                <PrintHead y={10} />
                <PrintHead y={70} />
            </>
        )
    }

    return null;
}

const Stamp: React.SFC<{}> = () => {
    return (
        <foreignObject id="stamp" x={210} y={10} width={28} height={35}>
            <img src="./stamp.png" alt="stamp" width="100%" />
        </foreignObject>
    )
}

const mapStateToProps = (state: ApplicationState) : EnvelopeStateProps => {
    return {
        showPrintHeads: state.showPrintHeads,
        cardSize: state.cardSize,
        orderItem: state.currentOrderItem
    };
  }
  
  const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => {
    return bindActionCreators({
      printHeadsShow: createPrintHeadsShow,
      printHeadsHide: createPrintHeadsHide
    }, dispatch);
  }

export default connect<EnvelopeStateProps, {}, EnvelopeDispatchProps, ApplicationState>(
    mapStateToProps,
    mapDispatchToProps
  )(Envelope)
  