
export class OrderItem {
    Id!: string;
    Address!: string;
    Name!: string;
    Note!: string;
    Price!: string;
    MatchCode!: string;
}

export class ScanEvent {
    ScanType!: ScanType;
    OrderItem!: OrderItem;
}

export enum ScanType {
    CONTINUOUS,
    HANDSCAN
}

