import store, { createContinuousScan, createHandScan } from './redux/store'
import { ScanEvent, ScanType } from './OrderItem'

export function startScanListener(){

    const ws = new WebSocket('ws://localhost:10000');

    ws.onopen = function () {
        console.log('websocket _ react app is connected ...')
        ws.send('connected: web_client [React]');
    }

    ws.onmessage = function (ev: any) {
        let currentState = store.getState();
        let scanEvent: ScanEvent = JSON.parse(ev.data);

        if(scanEvent.ScanType === ScanType.HANDSCAN){
            let handScan = createHandScan(scanEvent);
            store.dispatch(handScan);
        }
        
        if(scanEvent.ScanType === ScanType.CONTINUOUS && !currentState.handScanInProgress){
            let scan = createContinuousScan(scanEvent);
            store.dispatch(scan);
        }
    }
}