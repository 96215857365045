import { createStore } from 'redux'
import { OrderItem, ScanEvent } from '../OrderItem'

export interface ApplicationState {
  showPrintHeads: boolean;
  cardSize: CardSize,
  handScanInProgress: boolean,
  currentOrderItem: OrderItem
}

const initialState: ApplicationState = {
  showPrintHeads: false,
  cardSize: "standard",
  handScanInProgress: false,
  currentOrderItem: {
      Note: "",
      Price: "",
      MatchCode: "",
      Id: "",
      Name: "",
      Address: ""
  }
};

export const HAND_SCAN = 'HAND_SCAN'

interface HandScanAction {
  type: typeof HAND_SCAN
  payload: { orderItem: OrderItem }
}

export function createHandScan(scanEvent: ScanEvent) : HandScanAction
{
  return {
    type: HAND_SCAN,
    payload: { 
      orderItem: scanEvent.OrderItem
    }
  }
}

export const CONTINUOUS_SCAN = 'CONTINUOUS_SCAN'

interface ContinuousScanAction {
  type: typeof CONTINUOUS_SCAN
  payload: { orderItem: OrderItem }
}

export function createContinuousScan(scanEvent: ScanEvent) : ContinuousScanAction
{
  return {
    type: CONTINUOUS_SCAN,
    payload: { 
      orderItem: scanEvent.OrderItem
    }
  }
}

export const CONTINUOUS_SCAN_SHOW = 'CONTINUOUS_SCAN_SHOW'
interface ContinuousScanShowAction {
  type: typeof CONTINUOUS_SCAN_SHOW
}
export let createContinuousScanShow = () => ({type: CONTINUOUS_SCAN_SHOW});

export const PRINT_HEADS_SHOW = 'PRINT_HEADS_SHOW'
export const PRINT_HEADS_HIDE = 'PRINT_HEADS_HIDE'

interface PrintHeadsShowAction {
  type: typeof PRINT_HEADS_SHOW
}

interface PrintHeadsHideAction {
  type: typeof PRINT_HEADS_HIDE
}

export let createPrintHeadsShow = () => ({ type:  PRINT_HEADS_SHOW });
export let createPrintHeadsHide = () => ({ type:  PRINT_HEADS_HIDE });

export type PrintHeadsActionTypes = PrintHeadsHideAction | PrintHeadsShowAction

export const CARD_SIZE_SELECT = 'CARD_SIZE_SELECT'

export type CardSize = "square" | "standard"

interface CardSizeSelectAction {
  type: typeof CARD_SIZE_SELECT,
  cardSize: CardSize
}

interface PrintHeadsHideAction {
  type: typeof PRINT_HEADS_HIDE
}

export let createCardSizeSelect = (cardSize: CardSize) => ({ 
  type: CARD_SIZE_SELECT,
  cardSize: cardSize
});

export type ActionTypes = PrintHeadsActionTypes | CardSizeSelectAction | HandScanAction | ContinuousScanAction | ContinuousScanShowAction

export function auditReducer( state = initialState, action: ActionTypes): ApplicationState {

    switch (action.type) 
    {
      case PRINT_HEADS_HIDE:
        return { ...state, showPrintHeads: false }
      case PRINT_HEADS_SHOW:
        return { ...state, showPrintHeads: true }
      case CARD_SIZE_SELECT:
        return { ...state, cardSize: action.cardSize}
      case HAND_SCAN:
        return { ...state, currentOrderItem: action.payload.orderItem, handScanInProgress: true}
      case CONTINUOUS_SCAN:
          return { ...state, currentOrderItem: action.payload.orderItem}
      case CONTINUOUS_SCAN_SHOW:
        return { ...state, handScanInProgress: false}
      default:
          return state
    }
}

const store = createStore(auditReducer, initialState, (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
(window as any).__REDUX_DEVTOOLS_EXTENSION__());

export default store
