import React from 'react';

export interface PrintTextFieldProps {
    x: number;
    y: number;
    width: number;
    height: number;
    text: string;
    className: string;
}

export class PrintTextField extends React.Component<PrintTextFieldProps> {
  
    render(){
        let p = this.props;
        return (
            <foreignObject id="envelopeMessage" x={p.x} y={p.y} width={p.width} height={p.height}>                    
                <div className={p.className}>{p.text}</div>
            </foreignObject>
        );
    }

    static defaultProps = { 
        x: 10,
        y: 10, 
        height: 10,
        text: "",
        className: ""
    }

}