import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ApplicationState, ActionTypes, createPrintHeadsShow, createPrintHeadsHide, createContinuousScanShow } from './redux/store'
import Envelope from './Envelope'
import './Monitor.css'

export interface MonitorStateProps {
  showPrintHeads: boolean
  handScanInProgress: boolean
}

interface MonitorDispatchProps{
    printHeadsShow(): void
    printHeadsHide(): void
    enableContinuousScan(): void
}

type MonitorProps = MonitorStateProps & MonitorDispatchProps

class Monitor extends React.Component<MonitorProps> {

  printHeadsShow = () => this.props.printHeadsShow()
  printHeadsHide = () => this.props.printHeadsHide()
  handleOrderItemReceived = (message: any) => {
    console.log(message);
    let result = JSON.parse(message);
    console.log(`received: ${result}`)
  }

  render(){

    let currentView = <Envelope />;

    let showOrHideText = this.props.showPrintHeads ? "Hide" : "Show"
    let showOrHideFunc = this.props.showPrintHeads ? this.printHeadsHide : this.printHeadsShow
    
    return ( 
      <div>
        <button onClick={showOrHideFunc}>Print heads - {showOrHideText}</button>
        <button onClick={this.props.enableContinuousScan} disabled={!this.props.handScanInProgress}>Resume Continuous View </button>
        { currentView }
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): MonitorStateProps => {
  return {
    showPrintHeads: state.showPrintHeads,
    handScanInProgress: state.handScanInProgress
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => {
  return bindActionCreators({
    printHeadsShow: createPrintHeadsShow,
    printHeadsHide: createPrintHeadsHide,
    enableContinuousScan: createContinuousScanShow
  }, dispatch);
}

export default connect<MonitorStateProps, MonitorDispatchProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(Monitor)
