import React from 'react';

export interface PrintHeadProps {
    x: number;
    y: number;
    width: number;
    height: number;
}

export class PrintHead extends React.Component<PrintHeadProps, any> {

    render(){
        let p = this.props;
                    
        return (
                <rect x={p.x} y={p.y} width={p.width} height={p.height} fill="white" fillOpacity="0" stroke="red" strokeDasharray="1, 2, 1"></rect>                        
        );
    }

    static defaultProps = { 
        x: 15,
        width: 225,
        height: 50
    }
}
